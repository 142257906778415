.button {
  @apply px-8 py-3 h-[53px] bg-ppc-white text-ppc-boldblack rounded-2xl shadow-button-primary border-button-primary border-1 text-[15px] leading-[20px] font-medium whitespace-nowrap;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  &:hover {
    @apply text-ppc-white bg-gradient;
  }

  &:active {
    @apply bg-ppc-gray1 text-ppc-gray4 shadow-button-inset bg-none border-none;
  }
}

.button-with-icon {
  @apply flex items-center px-8 py-3 h-[47px] bg-gradient-to-l from-ppc-yellow to-ppc-flame text-ppc-white rounded-2xl shadow-button-primary border-button-primary border-1 text-[15px] leading-[20px] font-bold whitespace-nowrap;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  &:hover {
    @apply bg-ppc-black;
    background-image: none;
  }

  &:active {
    @apply bg-ppc-gray1 text-ppc-gray4 shadow-button-inset bg-none border-none;
  }
}

.button-full {
  @apply px-8 py-3 h-[64px] bg-ppc-yellow text-ppc-boldblack rounded-2xl text-[19px] leading-[23px] font-bold whitespace-nowrap w-full;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  &:hover {
    @apply text-ppc-white bg-black;
  }

  &:active {
    @apply bg-ppc-gray1 text-ppc-gray4 shadow-button-inset bg-none border-none;
  }
}